<br />
<div
  class="center"
  *ngIf="!hasRefreshToken && canShowShare()">
  <a
    href="https://sellercentral.amazon.co.jp/apps/authorize/consent?application_id=amzn1.sellerapps.app.2061b3cc-fc3b-48cc-8b52-fd333bc47f68&state=link">
    <p-button
      label="まずはここをクリックしてAmazon連携を行ってください"
      styleClass="p-button-danger p-button-lg"></p-button>
  </a>
</div>
<h2 class="center">
  「{{ groupName }}」のらくじゃんへようこそ
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-bell"
    class="p-button-rounded p-button-warning p-button-outlined"
    style="font-size: 2rem"
    severity="danger"
    (click)="goHistory()"></button>
  <p-badge
    *ngIf="showNewIcon()"
    value="New"
    severity="danger"
    size="null"></p-badge>
</h2>

<div
  class="grid"
  *ngFor="let feature of userFeatures">
  <div class="col-1 lg:col-3 xl:col-3"></div>

  <div class="col-5 lg:col-3 xl:col-3">
    <p-card
      class="clickable"
      routerLink="{{ feature[0].path }}">
      <div class="center">
        <i class="pi {{ feature[0].icon }} icon-big"></i>
      </div>
      <h2 class="center">{{ feature[0].title }}</h2>
    </p-card>
  </div>

  <div
    class="col-5 lg:col-3 xl:col-3"
    *ngIf="getFeatureIndex(1, feature)">
    <p-card
      class="clickable"
      routerLink="{{ getFeatureIndex(1, feature)?.path }}">
      <div class="center">
        <i class="pi {{ getFeatureIndex(1, feature)?.icon }} icon-big"></i>
      </div>
      <h2 class="center">{{ getFeatureIndex(1, feature)?.title }}</h2>
    </p-card>
  </div>

  <div class="col-1 lg:col-3 xl:col-3"></div>

  <!--
  <div class="col-3" *ngIf="isStoreManager">
    <p-card
      class="clickable"
      styleClass="p-primary"
      routerLink="/price-tracker"
    >
      <div class="center">
        <i class="pi pi-bell icon-big"></i>
      </div>
      <h2 class="center">Amazon価格追跡</h2>
    </p-card>
  </div>
  -->

  <!--
  <div class="col-3"></div>
  <div class="col-3">
    <p-card class="clickable" routerLink="/products/manage" *ngIf="isAdmin">
      <div class="center">
        <i class="pi pi-list icon-big"></i>
      </div>
      <h2 class="center">
        商品管理
      </h2>
    </p-card>
  </div>
  <div class="col-3"></div>
  -->
</div>
