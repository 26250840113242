import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {
  DiscontinuedCategoriesGQL,
  DiscontinuedCategoriesQuery,
  DiscontinueProductsGQL,
  DiscontinueProductsQuery,
  RegisterDiscontinuedCategoryGQL,
  RegisterDiscontinuedCategoryMutation,
  RegisterDiscontinuedJansGQL,
  RegisterDiscontinuedJansMutation,
  SearchDiscontinuedProductInput,
  UpsertDiscontinuedMemoGQL,
  UpsertDiscontinuedMemoMutation,
} from 'src/gql/generated';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { GraphQLFormattedError } from 'graphql';

@Injectable({
  providedIn: 'root',
})
export class DiscontinueService {
  constructor(
    private http: HttpService,
    private categoryGQL: DiscontinuedCategoriesGQL,
    private productsGQL: DiscontinueProductsGQL,
    private registerCategoryGQL: RegisterDiscontinuedCategoryGQL,
    private registerJansGQL: RegisterDiscontinuedJansGQL,
    private upsertMemoGQL: UpsertDiscontinuedMemoGQL,
  ) {}

  public async fetchProducts(input: SearchDiscontinuedProductInput): Promise<DiscontinueProductsQuery | null> {
    const variables = {
      input,
    };
    const resp = await firstValueFrom(this.productsGQL.fetch(variables));

    if (!resp.errors) {
      return resp.data;
    }

    return this.handleError(resp.errors);
  }

  public async fetchCategories(): Promise<DiscontinuedCategoriesQuery | null> {
    const resp = await firstValueFrom(this.categoryGQL.fetch());

    if (!resp.errors) {
      return resp.data;
    }
    return this.handleError(resp.errors);
  }

  public async registerCategoryName(name: string): Promise<RegisterDiscontinuedCategoryMutation | null> {
    const resp = await firstValueFrom(this.registerCategoryGQL.mutate({ name }));

    if (!resp.errors) {
      return resp.data;
    }
    return this.handleError(resp.errors);
  }

  public async upsertMemo(tmpId: number, memo: string): Promise<UpsertDiscontinuedMemoMutation | null> {
    const id = tmpId.toString();
    const resp = await firstValueFrom(this.upsertMemoGQL.mutate({ id, memo }));

    if (!resp.errors) {
      return resp.data;
    }
    return this.handleError(resp.errors);
  }

  public async registerJans(jans: string[], categoryId: number): Promise<RegisterDiscontinuedJansMutation | null> {
    const resp = await firstValueFrom(this.registerJansGQL.mutate({ jans, categoryId }));

    if (!resp.errors) {
      return resp.data;
    }
    return this.handleError(resp.errors);
  }

  private handleError(errors?: readonly GraphQLFormattedError[]) {
    if (this.http.hasExtensions(errors)) {
      this.http.handleError(errors[0].extensions);
    } else {
      this.http.handleError();
    }
    return null;
  }
}
