<a id="csv-donwload"></a>

<p-panel
  header="検索"
  [toggleable]="true"
  [collapsed]="false">
  <div class="grid">
    <div class="col-12">
      <label for="ms-groupedArea">都道府県での絞り込み</label>
      <div class="card justify-content-center p-label">
        <p-multiSelect
          [options]="groupedArea"
          [group]="true"
          [(ngModel)]="selectedArea"
          (onChange)="doChangeArea($event)"
          defaultLabel="表示したい都道府県を選択してください"
          display="chip">
          <ng-template
            let-group
            pTemplate="group">
            <div class="flex align-items-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
    <div class="col-12">
      <label for="ms-groupedStores">店舗での絞り込み</label>
      <div class="card justify-content-center p-label">
        <p-multiSelect
          [options]="groupedStores"
          [group]="true"
          [(ngModel)]="selectedStores"
          (onChange)="doChangeStores($event)"
          defaultLabel="表示したい店舗を選択してください"
          display="chip">
          <ng-template
            let-group
            pTemplate="group">
            <div class="flex align-items-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
    <div class="col-12">
      <label for="ms-groupedStores">登録日時での絞り込み</label>
      <div class="card justify-content-center p-label">
        <p-dropdown
          [options]="registrationDateOptions"
          [(ngModel)]="filterRegistrationDate"
          (onChange)="doChangeRegistrationDate($event)"
          optionLabel="name"
          placeholder="登録日時" />
      </div>
    </div>
    <div class="col-12">
      <div class="p-inputgroup full-height">
        <div class="flex align-items-center gap-1">
          <p-checkbox
            [(ngModel)]="showPurchased"
            inputId="showPurchased"
            [binary]="true"
            (onChange)="reloadStocks($event)"></p-checkbox>
          <label for="showPurchased">購入/展示商品を表示する</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="p-inputgroup full-height">
        <div class="flex align-items-center gap-1">
          <p-checkbox
            [(ngModel)]="showFound"
            inputId="showFound"
            [binary]="true"
            (onChange)="reloadStocks($event)"></p-checkbox>
          <label for="showFound">試無商品を非表示にする</label>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="p-inputgroup full-height">
        <div class="flex align-items-center gap-1">
          <p-checkbox
            [(ngModel)]="filterOneStock"
            inputId="filterOneStock"
            [binary]="true"
            (onChange)="reloadStocks($event)"></p-checkbox>
          <label for="filterOneStock">在庫数:1の店舗を非表示にする</label>
        </div>
      </div>
    </div>
  </div>
</p-panel>

<br />
<br />

<p-table
  #table
  [value]="products"
  columnResizeMode="expand"
  responsiveLayout="stack"
  [tableStyle]="{ 'min-width': '50rem' }"
  class="table-header-sticky"
  sortMode="multiple"
  [paginator]="true"
  [rows]="300"
  [globalFilterFields]="['jan', 'asin', 'name']">
  <ng-template pTemplate="caption">
    <div class="table-caption">
      <div class="grid">
        <div class="col-3">
          <div class="p-d-flex">
            <button
              *ngIf="!isTestAccount"
              type="button"
              pButton
              pRipple
              icon="pi pi-download"
              (click)="downloadCsv(table)"
              class="p-mr-2"
              pTooltip="CSV"
              tooltipPosition="bottom"></button>
          </div>
        </div>
        <div class="col-5"></div>
        <div class="col-3">
          <span class="p-input-icon-left ml-auto input-full">
            <i class="pi pi-search"></i>
            <input
              pInputText
              class="input-full"
              type="text"
              (input)="table.filterGlobal($event.target.value, 'contains')"
              placeholder="検索キーワード" />
          </span>
        </div>
        <div class="col-1">
          <button
            pButton
            label="Clear"
            class="p-button-outlined"
            icon="pi pi-filter-slash"
            (click)="table.clear()"></button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th width="6%">画像</th>
      <th width="10%">Keepaグラフ</th>
      <th width="8%">JAN</th>
      <th width="8%">ASIN</th>
      <th
        width="5%"
        pSortableColumn="price">
        価格
        <p-sortIcon field="price"></p-sortIcon>
      </th>
      <th width="20%">商品名</th>
      <th width="50%">在庫状況</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-product>
    <!--<tr class="{{ getRowStyle(product) }}"> -->
    <tr>
      <td>
        <a
          href="https://www.amazon.co.jp/dp/{{ product.asin }}"
          target="_blank">
          <img
            src="{{ product.imageUrl }}"
            style="width: 50px" />
        </a>
      </td>
      <td>
        <a
          href="https://keepa.com/#!product/5-{{ product.asin }}"
          target="_blank">
          <img
            style="max-width: 100%"
            src="https://graph.keepa.com/pricehistory.png?asin={{
              product.asin
            }}&domain=co.jp&width=500&height=250&salesrank=1" />
        </a>
      </td>
      <td>
        {{ product.jan }}
        <br />
        登録日: {{ product.registrationDate | date: 'yyyy/MM/dd' }}
        <br />
        総在庫数: {{ product.totalStocks }}
      </td>
      <td>
        {{ product.asin }}
        <br />
        ({{ product.amazonPrice?.toLocaleString() }})
      </td>
      <td>
        {{ product.price.toLocaleString() }}
      </td>
      <td>
        <a
          href="https://www.amazon.co.jp/dp/{{ product.asin }}"
          target="_blank">
          {{ product.name }}
        </a>
      </td>
      <td>
        <app-spreadsheet-stock-badge
          [stocks]="product.filteredStocks"
          [showPurchased]="showPurchased"></app-spreadsheet-stock-badge>
      </td>
    </tr>
  </ng-template>
</p-table>
