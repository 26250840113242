import { Component, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { DonkiProduct, DonkiStock } from 'src/app/models/donki-product';
import { MessageService } from 'src/app/services/message.service';
import { RoutingService } from 'src/app/services/routing.service';
import { SubtitleService } from 'src/app/services/subtitle.service';
import { UserService } from 'src/app/services/user.service';
import { DonkiProductsGQL, UpsertDonkiProductMemoGQL } from 'src/gql/generated';

@Component({
  selector: 'app-donki',
  templateUrl: './donki.component.html',
  styleUrl: './donki.component.sass',
})
export class DonkiComponent {
  searchText: string;
  form: UntypedFormGroup;
  isLoading: boolean;

  products: Array<DonkiProduct> = [];
  allProducts: Array<DonkiProduct> = [];
  showPurchased = false;
  showFound = false;
  isTestAccount = false;

  canRegisterNewItems = true;

  finishedBuildStores = false;
  selectedArea = [];
  groupedArea = [];
  allStores = [];
  selectedStores = [];
  groupedStores = [];

  queueItems = [];

  splitStr = '::';
  selectedAreaKey = 'savedDonkiSelectedAreaKey';
  selectedStoreKey = 'savedDonkiSelectedStoreKey';

  constructor(
    private elementRef: ElementRef,
    private message: MessageService,
    private subtitleService: SubtitleService,
    private routingService: RoutingService,
    private userService: UserService,
    private getGql: DonkiProductsGQL,
    private memoGQL: UpsertDonkiProductMemoGQL,
  ) {
    this.isLoading = false;
    this.routingService.loginRequired();
    this.userService.spreadsheetRequired();
    this.isTestAccount = this.userService.getEmail() == 'test@rakujan.com';
    this.subtitleService.setSubTitle('ドンキ');
  }

  async ngOnInit() {
    this.selectedArea = JSON.parse(localStorage.getItem(this.selectedAreaKey)) || [];
    this.selectedStores = JSON.parse(localStorage.getItem(this.selectedStoreKey)) || [];
    await this.loadStocks();
  }

  async loadStocks() {
    const resp = await firstValueFrom(this.getGql.fetch());
    this.products = resp.data.donkiProducts.map((p) => {
      var hasAnyStock = false;
      const stocks = p.stocks.map((s) => {
        hasAnyStock = hasAnyStock || !s.operation?.isPurchased;
        return {
          type: 'donki',
          id: s.id,
          memo: s.operation?.memo,
          createdAt: s.createdAt,
          isPurchased: s.operation?.isPurchased || false,
          purchasedUserName: s.operation?.purchasedUser?.name || '',
          isNotFound: s.operation?.isNotFound || false,
          stockNum: s.stockNum,
          price: s.price,
          storeId: s.store.id,
          storeName: s.store.name,
          areaId: s.store.area.id,
          areaName: s.store.area.name,
        } as DonkiStock;
      });

      const sp = p.product;
      const mainAp = sp.amazonProducts.find((ap) => ap.isMain);
      const ap = mainAp ?? (sp.amazonProducts.length > 0 ? p.product.amazonProducts[0] : null);
      return {
        id: p.id,
        jan: sp.jan,
        asin: ap?.asin || '',
        amazonPrice: ap?.latestPrice || -1,
        imageUrl: ap?.imageUrl || '',
        name: ap?.name || '',
        hasAnyStock: hasAnyStock,
        stocks: stocks,
        filteredStocks: this.doFilters(stocks),
      };
    });

    this.allProducts = [...this.products];

    var area = {
      label: '都道府県',
      value: 'area',
      items: [],
    };
    this.groupedStores = [];

    resp.data.storeAreas.forEach((sa) => {
      area.items.push({
        label: sa.name,
        value: sa.id,
      });

      var store = {
        label: sa.name,
        value: sa.id,
        items: [],
      };
      sa.donkiStores.forEach((s) => {
        store.items.push({
          label: s.name,
          value: s.id,
        });
      });
      this.groupedStores.push(store);
    });

    this.allStores = [...this.groupedStores];
    this.groupedArea = [area];

    if (this.selectedArea.length > 0) {
      this.doChangeArea(null);
    }
    if (this.selectedStores.length > 0) {
      this.doChangeStores(null);
    }
  }

  async doChangeShowPurchased($e) {
    await this.loadStocks();
  }

  doChangeArea($e) {
    localStorage.setItem(this.selectedAreaKey, JSON.stringify(this.selectedArea));
    this.products = [];
    this.allProducts.forEach((p) => {
      p.filteredStocks = this.doFilters(p.stocks);
      if (p.filteredStocks.length > 0) {
        this.products.push(p);
      }
    });
    this.groupedStores = this.allStores.filter((s) => {
      return this.selectedArea.includes(s.value);
    });
  }

  doChangeStores($e) {
    localStorage.setItem(this.selectedStoreKey, JSON.stringify(this.selectedStores));
    this.products = [];
    this.allProducts.forEach((p) => {
      p.filteredStocks = this.doFilters(p.stocks);
      if (p.filteredStocks.length > 0) {
        this.products.push(p);
      }
    });
  }

  doFilters(stocks: DonkiStock[]) {
    stocks = this.doFilterStocks(stocks);
    stocks = this.doFilterArea(stocks);
    stocks = this.doFilterStores(stocks);
    return stocks;
  }

  private doFilterStocks(stocks: DonkiStock[]) {
    if (stocks.length === 0) {
      return stocks;
    }
    const needsToFilter = !this.showPurchased || this.showFound;
    if (needsToFilter) {
      return stocks.filter((s) => {
        if (!this.showPurchased) {
          if (s.isPurchased) {
            return false;
          }
        }
        if (this.showFound) {
          if (s.isNotFound) {
            return false;
          }
        }
        return true;
      });
    }
    return stocks;
  }

  private doFilterArea(stocks: DonkiStock[]) {
    if (this.selectedArea.length === 0 || stocks.length === 0) {
      return stocks;
    }
    // filter pref and store nmaes
    return stocks.filter((s) => {
      return this.selectedArea.includes(s.areaId);
    });
  }

  private doFilterStores(stocks: DonkiStock[]) {
    if (this.selectedStores.length === 0 || stocks.length === 0) {
      return stocks;
    }
    // filter pref and store nmaes
    return stocks.filter((s) => {
      return this.selectedStores.includes(s.storeId);
    });
  }

  async reloadStocks($e) {
    await this.loadStocks();
  }

  getRowStyle(product: DonkiProduct) {
    if (!product.hasAnyStock) {
      if (this.showPurchased) {
        return 'unpublic';
      }
      return 'hide-table-row';
    }
    const isAllPurchased = product.filteredStocks.filter((s) => s.isPurchased).length == 0;
    if (isAllPurchased) {
      if (this.showPurchased) {
        return 'unpublic';
      }
      return 'hide-table-row';
    }

    return '';
  }

  downloadCsv(table: any) {
    const products: DonkiProduct[] = table.filteredValue ?? this.products;
    const values = products.filter((p) => p.filteredStocks.length > 0);
    const csv = values
      .map((p) => {
        const memo = p.filteredStocks
          .map((s) => {
            return s.storeName + ':' + s.stockNum;
          })
          .join('/');
        //return p.jan + ',' + p.posPrice + ',1,,"' + (p.discountPrice ?? '') + '_' + (memo ?? '') + '"';
        return '現在調整中です';
      })
      .join('\n');
    this.outputCsv(csv, 'donki.csv');
  }

  private outputCsv(csv: string, filename: string) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const elem = this.elementRef.nativeElement;
    const link = elem.querySelector('#csv-donwload') as HTMLAnchorElement;
    link.href = url;
    link.download = filename;
    link.click();
  }
}
