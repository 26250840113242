<a id="csv-donwload"></a>

<p-panel
  header="検索"
  [toggleable]="true"
  [collapsed]="false">
  <form
    [formGroup]="form"
    #f="ngForm">
    <div class="grid">
      <div class="col-6">
        <div>
          <b>JAN検索</b>
        </div>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
          <textarea
            placeholder="JAN or ASIN検索"
            rows="4"
            id="keyword"
            formControlName="keyword"
            class="input-full"></textarea>
        </div>
      </div>
      <div class="col-6">
        <div>
          <b>その他設定</b>
        </div>
        <div class="field-checkbox">
          <p-checkbox
            formControlName="showOldProducts"
            [binary]="true"
            inputId="binary"></p-checkbox>
          <label for="binary">過去商品を表示する</label>
        </div>
      </div>
      <div class="col-2">
        <p-button
          styleClass="p-button-success"
          label="JAN or ASIN検索"
          icon="pi pi-search"
          (onClick)="doSearch()"></p-button>
      </div>
      <div class="col-2">
        <p-button
          styleClass="p-button"
          label="前月比5%増の商品のみ表示"
          icon="pi pi-search"
          (onClick)="doSearchFive()"></p-button>
      </div>
      <div class="col-2">
        <p-button
          styleClass="p-button"
          label="全て表示"
          icon="pi pi-search"
          (onClick)="doShowAll()"></p-button>
      </div>
    </div>
  </form>
</p-panel>
<p-table
  #irisTable
  [value]="irisProducts"
  columnResizeMode="expand"
  styleClass="p-datatable-responsive"
  tableStyleClass="p-datatable-responsive"
  class="table-header-sticky"
  sortMode="multiple"
  [paginator]="true"
  [rows]="100"
  [globalFilterFields]="['productCode', 'productName', 'asin', 'jan', 'modelNumber']">
  <ng-template pTemplate="caption">
    <div class="table-caption">
      <div class="grid">
        <div class="col-3">
          <div class="p-d-flex">
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-download"
              (click)="downloadCsv(irisTable)"
              class="p-mr-2"
              pTooltip="CSV"
              tooltipPosition="bottom"></button>
          </div>
        </div>
        <div class="col-3"></div>
        <div class="col-5">
          <span class="p-input-icon-left ml-auto input-full">
            <i class="pi pi-search"></i>
            <input
              pInputText
              class="input-full"
              type="text"
              (input)="irisTable.filterGlobal($event.target.value, 'contains')"
              placeholder="検索キーワード" />
          </span>
        </div>
        <div class="col-1">
          <button
            pButton
            label="Clear"
            class="p-button-outlined"
            icon="pi pi-filter-slash"
            (click)="clear(irisTable)"></button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th
        width="5%"
        pSortableColumn="productCode">
        商品コード
        <p-sortIcon field="productCode"></p-sortIcon>
      </th>
      <th width="15%">商品名</th>
      <th width="7%">JAN/ASIN</th>
      <th
        width="3%"
        pSortableColumn="modelNumber">
        型番
        <p-sortIcon field="modelNumber"></p-sortIcon>
      </th>
      <th
        width="5%"
        pSortableColumn="discontinuedSchedule">
        廃盤予定
        <p-sortIcon field="discontinuedSchedule"></p-sortIcon>
      </th>
      <th
        width="5%"
        pSortableColumn="note">
        備考
        <p-sortIcon field="note"></p-sortIcon>
      </th>
      <th
        width="5%"
        pSortableColumn="lvonPrice">
        LVON価格
        <p-sortIcon field="lvonPrice"></p-sortIcon>
      </th>
      <th
        width="5%"
        pSortableColumn="amazonPrice">
        販売価格
        <p-sortIcon field="amazonPrice"></p-sortIcon>
      </th>
      <th
        width="3%"
        pSortableColumn="profitPrice">
        利益額
        <p-sortIcon field="profitPrice"></p-sortIcon>
      </th>
      <th
        width="3%"
        pSortableColumn="profitRate">
        利益率
        <p-sortIcon field="profitRate"></p-sortIcon>
        <p-columnFilter
          field="profitRate"
          matchMode="between"
          display="menu"
          [showMatchModes]="false"
          [showOperator]="false"
          [showAddButton]="false">
          <ng-template
            pTemplate="filter"
            let-filter="filterCallback">
            <p-slider
              [ngModel]="profitValues"
              [range]="true"
              (onSlideEnd)="filter($event.values)"
              [min]="0"
              [max]="1"
              [step]="0.01"></p-slider>
            <div class="flex align-items-center justify-content-center px-2">
              <span>{{ profitValues[0] }} -</span>
              <span>{{ profitValues[1] }}</span>
            </div>
          </ng-template>
        </p-columnFilter>
      </th>
      <th
        width="3%"
        pSortableColumn="orderLevel">
        発注レベル
        <p-sortIcon field="orderLevel"></p-sortIcon>
        <p-columnFilter
          field="orderLevel"
          matchMode="in"
          display="menu"
          [showMatchModes]="false"
          [showOperator]="false"
          [showAddButton]="false">
          <ng-template
            pTemplate="filter"
            let-value
            let-filter="filterCallback">
            <p-selectButton
              [options]="filterOrderLevels"
              [ngModel]="value"
              multiple="multiple"
              optionLabel="name"
              optionValue="value"
              (onChange)="filter($event.value)"></p-selectButton>
          </ng-template>
        </p-columnFilter>
      </th>
      <th
        width="4%"
        pSortableColumn="useFba">
        FBA納品
        <p-sortIcon field="useFba"></p-sortIcon>
      </th>
      <th width="5%">メモ</th>
      <th
        width="3%"
        pSortableColumn="lastImportedAt">
        最終更新日
        <p-sortIcon field="lastImportedAt"></p-sortIcon>
      </th>
      <th
        width="3%"
        pSortableColumn="createdAt">
        登録日
        <p-sortIcon field="createdAt"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-iris>
    <tr
      class="{{ getRowStyle(iris.isOldProduct) }}"
      *ngIf="canShowRecord(iris)">
      <td>
        {{ iris.productCode }}
        <br />
        <p-badge
          [value]="1980"
          severity="danger"
          styleClass="mr-2"
          *ngIf="isOverSize160(iris)"></p-badge>
        <br />
        <p-tag
          value="大型"
          severity="warning"
          styleClass="mr-2"
          *ngIf="iris.isBigType"></p-tag>
      </td>
      <td>
        {{ iris.productName }}
        <br />
        <a
          href="https://keepa.com/#!product/5-{{ iris.firstAsin }}"
          target="_blank">
          <img
            style="max-width: 100%"
            src="https://graph.keepa.com/pricehistory.png?asin={{
              iris.firstAsin
            }}&domain=co.jp&width=500&height=250&salesrank=1" />
        </a>
      </td>
      <td>
        {{ iris.jan }},
        <span [innerHtml]="iris.asinsHtml"></span>
      </td>
      <td>
        {{ iris.modelNumber }}
      </td>
      <td>
        {{ iris.discontinuedSchedule }}
      </td>
      <td>
        {{ iris.note }}
      </td>
      <td>
        {{ iris.lvonPriceInTax.toLocaleString() }}
        <br />
        ({{ iris.lvonPrice.toLocaleString() }})
        <div
          *ngIf="getLvonDiffPrice(iris)"
          style="{{ getProfitStyle(getLvonDiffPrice(iris)) }}">
          (前月比{{ getLvonDiffPrice(iris) }})
        </div>
      </td>
      <td>
        {{ iris.amazonPrice.toLocaleString() }}
        <br />
        ({{ getViewFee(iris.fee) }})
      </td>
      <td>
        <span style="{{ getProfitStyle(iris.profitPrice) }}">
          {{ iris.profitPrice.toLocaleString() }}
        </span>
      </td>
      <td>
        <span style="{{ getProfitStyle(iris.profitPrice) }}">
          {{ iris.profitRate | percent: '1.1' }}
        </span>
      </td>
      <td>
        <p-tag
          value="{{ getOrderLevel(iris.orderLevel) }}"
          *ngIf="iris.orderLevel && iris.orderLevel != '-'"></p-tag>
        <br />
        <i
          class="pi pi-cog clickable"
          (click)="openOrderLevelSetting(opOrderLevel, $event, iris)"></i>
        <div *ngIf="hasLastOrderLevel()">
          <button
            pButton
            type="button"
            label="同"
            class="p-button-sm p-button-rounded"
            (click)="updateOrderLevel(null, lastOrderLevel, iris)"></button>
        </div>
      </td>
      <td>
        <p-inputSwitch
          (onChange)="setUseFba($event, iris)"
          [(ngModel)]="iris.useFba"></p-inputSwitch>
      </td>
      <td pEditableColumn>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <textarea
              pInputTextarea
              [(ngModel)]="iris.memo"
              (blur)="updateMemo(iris)"
              (keydown.enter)="updateMemo(iris)"></textarea>
          </ng-template>
          <ng-template pTemplate="output">
            {{ iris.memo }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>{{ iris.lastImportedAt }}</td>
      <td>{{ iris.createdAt }}</td>
    </tr>
  </ng-template>
</p-table>

<p-overlayPanel #opOrderLevel>
  <ng-template pTemplate>
    <p-selectButton
      [options]="orderLevels"
      optionLabel="name"
      optionValue="value"
      (onChange)="updateOrderLevel(opOrderLevel, $event.value)"></p-selectButton>
  </ng-template>
</p-overlayPanel>
