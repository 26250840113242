import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import {
  SigninGQL,
  MeGQL,
  RefreshGQL,
  SignoutGQL,
  RegisterTokenValidateGQL,
  RegisterUserGQL,
  UsersGQL,
  UsersQuery,
  MeWithTelegramQuery,
  MeWithTelegramGQL,
  MeQuery,
} from 'src/gql/generated';
import { ErrorCode } from '../models/error-code.enum';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly USER_STORE_KEY = 'user_key';

  private readonly funcRakuten = 'rakuten';
  private readonly funcShare = 'share';
  private readonly funcShareKojimaBic = 'share_kojima_bic';
  private readonly funcBiccamera = 'biccamera';
  private readonly funcKojima = 'kojima';
  private readonly funcIris = 'iris';
  private readonly funcSpreadSheet = 'spreadsheet';
  private readonly funcDiscontinue = 'discontinue';

  private user: User;
  private logined: boolean;

  constructor(
    private router: Router,
    private http: HttpService,
    private signinGQL: SigninGQL,
    private meGQL: MeGQL,
    private refreshGQL: RefreshGQL,
    private signoutGQL: SignoutGQL,
    private registerTokenValidateGQL: RegisterTokenValidateGQL,
    private registerUserGQL: RegisterUserGQL,
    private usersGQL: UsersGQL,
    private meWithTelegramGQL: MeWithTelegramGQL,
  ) {
    const initLoginFunc = (obj) => {
      const user: User = JSON.parse(obj);
      if (user) {
        this.user = user;
        this.user.storeTime = new Date(this.user.storeTime);
        this.logined = true;
      }
    };
    const userObj = localStorage.getItem(this.USER_STORE_KEY);
    if (userObj) {
      initLoginFunc(userObj);
    } else {
      const sessionObj = sessionStorage.getItem(this.USER_STORE_KEY);
      if (sessionObj) {
        initLoginFunc(sessionObj);
      }
    }
  }

  public registerCheck(token: string, success: any, error?: any) {
    this.registerTokenValidateGQL.fetch({ token }).subscribe(
      (resp) => {
        if (resp.errors) {
          if (this.http.hasExtensions(resp.errors) && error) {
            error(resp.errors[0].extensions);
          } else {
            this.http.handleError();
          }
        } else {
          success(resp);
        }
      },
      (err) => {
        error(err);
      },
    );
  }

  public register(password: string, salt: string, token: string, success: any, error?: any) {
    const body = { password, salt, token };
    this.registerUserGQL.mutate(body).subscribe((resp) => {
      if (resp.errors) {
        if (this.http.hasExtensions(resp.errors) && error) {
          error(resp.errors[0].extensions);
        } else {
          this.http.handleError();
        }
      } else {
        success(resp.data.registerUser);
      }
    });
  }

  public async login(email: string, password: string, isRemember: boolean, success: any, error?: any) {
    const body = { email, password, serviceType: 'nono' };
    const resp = await firstValueFrom(this.signinGQL.mutate(body));
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      await this.loginProcess(email, resp.data.signin.token, isRemember);
      success(resp);
    }
  }

  public async loginProcess(email: string, token: string, isRemember: boolean) {
    this.user = new User();
    this.user.email = email;
    this.user.token = token;
    this.user.storeTime = new Date();
    this.logined = true;
    if (isRemember) {
      this.user.storeType = 'local';
    } else {
      this.user.storeType = 'session';
    }
    const me = await firstValueFrom(this.meGQL.fetch());
    this.handleMeResp(me, () => {});
    this.saveUser();
  }

  public logout(success: any, error?: any) {
    this.signoutGQL.mutate().subscribe((resp) => {
      if (resp.errors) {
        // logoutでエラーが起きたら、強制的にログアウトする
        this.forceLogout(resp.errors, error);
      } else {
        this.logined = false;
        localStorage.removeItem(this.USER_STORE_KEY);
        sessionStorage.removeItem(this.USER_STORE_KEY);
        success(resp.data);
      }
    });
  }

  public forceLogout(err: any, error?: any) {
    this.logined = false;
    localStorage.removeItem(this.USER_STORE_KEY);
    sessionStorage.removeItem(this.USER_STORE_KEY);
    if (error) {
      error(err);
    } else {
      this.http.handleError(err);
    }
  }

  public async refresh(operationName: string): Promise<string> {
    if (!!!this.user) {
      // 未ログインは何もしない
      return null;
    }
    if (operationName === 'refresh') {
      // すでにrefresh api requestだったら何もしない
      return null;
    }
    if (operationName === 'signout') {
      // logout api requestだったら何もしない
      return null;
    }
    const st = new Date(this.user.storeTime.getTime());
    st.setMinutes(st.getMinutes() + environment.tokenRefreshMin);
    const needRefresh = st.getTime() < new Date().getTime();
    if (!needRefresh) {
      return null;
    }
    await this.doRefresh();

    return null;
  }

  public me(success: any, error?: any): void {
    this.meGQL.fetch().subscribe((resp) => {
      this.handleMeResp(resp, success, error);
    });
  }

  public isLogined(): boolean {
    return this.logined;
  }

  public getUserToken(): string {
    if (this.user) {
      return this.user.token;
    }
    return null;
  }

  public getEmail(): string {
    if (this.user) {
      return this.user.email;
    }
    return null;
  }

  public getName(): string {
    if (this.user) {
      return this.user.name;
    }
    return null;
  }

  public isStoreManager(): boolean {
    if (this.user) {
      return this.user.isStoreManager;
    }
    return false;
  }

  public isAdmin(): boolean {
    if (this.user) {
      return this.user.userRole === 'admin';
    }
    return false;
  }

  public hasSubRole(name: string): boolean {
    return this.user?.userSubRoles?.includes(name);
  }

  public async getAllUsers(success: (resp: UsersQuery) => void, error?) {
    const serviceType = 'nono';
    const resp = await this.usersGQL.fetch({ serviceType }, {}).toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }

  public async getUserTelegram(success: (resp: MeWithTelegramQuery) => void, error?) {
    const resp = await this.meWithTelegramGQL.fetch().toPromise();
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      success(resp.data);
    }
  }

  public hasFuncRakuten(): boolean {
    return this.hasFuncShow(this.funcRakuten);
  }

  public rakutenRequired(): void {
    this.funcRequired([this.funcRakuten]);
  }

  public hasFuncShare(): boolean {
    return this.hasFuncShow(this.funcShare) || this.hasFuncShow(this.funcShareKojimaBic);
  }

  public shareRequired(): void {
    this.funcRequired([this.funcShare, this.funcShareKojimaBic]);
  }

  public hasShareKojimaBicOnly(): boolean {
    const hasKojimaBic = this.hasFuncShow(this.funcShareKojimaBic);
    const hasShare = this.hasFuncShow(this.funcShare);
    return hasKojimaBic && !hasShare;
  }

  public hasFuncAmazonNotify(): boolean {
    // 商品共有があればAmazon通知も使える
    return this.hasFuncShow(this.funcShare);
  }

  public amazonNotifyRequired(): void {
    // 商品共有があればAmazon通知も使える
    this.funcRequired([this.funcShare]);
  }

  public hasFuncBiccamera(): boolean {
    return this.hasFuncShow(this.funcBiccamera);
  }

  public biccameraRequired(): void {
    this.funcRequired([this.funcBiccamera]);
  }

  public hasFuncKojima(): boolean {
    return this.hasFuncShow(this.funcKojima);
  }

  public kojimaRequired(): void {
    this.funcRequired([this.funcKojima]);
  }

  public hasFuncIris(): boolean {
    return this.hasFuncShow(this.funcIris);
  }

  public irisRequired(): void {
    this.funcRequired([this.funcIris]);
  }

  public hasFuncSpreadSheet(): boolean {
    return this.hasFuncShow(this.funcSpreadSheet);
  }

  public spreadsheetRequired(): void {
    this.funcRequired([this.funcSpreadSheet]);
  }

  public hasFuncDiscontinue(): boolean {
    return this.hasFuncShow(this.funcDiscontinue);
  }

  public discontinueRequired(): void {
    this.funcRequired([this.funcDiscontinue]);
  }

  // --------------------- private ---------------------
  private handleMeResp(resp: any, success: any, error?: any) {
    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors) && error) {
        error(resp.errors[0].extensions);
      } else {
        this.http.handleError();
      }
    } else {
      const me = resp.data.me;
      this.user.name = me.name;
      this.user.userRole = me.userRole;
      this.user.isStoreManager = me.isStoreManager;
      this.user.userSubRoles = me.userSubRoles;
      this.user.functions = me.functions;
      success(me);
    }
  }

  private async doRefresh() {
    const resp = await firstValueFrom(this.refreshGQL.mutate());

    if (resp.errors) {
      if (this.http.hasExtensions(resp.errors)) {
        const code = resp.errors[0].extensions.code;
        if (typeof code === 'string') {
          return code;
        }
      }
      return ErrorCode.InternalServerError;
    }
    this.saveUser();
  }

  private saveUser() {
    this.user.storeTime = new Date();
    if (this.user.storeType === 'local') {
      localStorage.setItem(this.USER_STORE_KEY, JSON.stringify(this.user));
    } else {
      sessionStorage.setItem(this.USER_STORE_KEY, JSON.stringify(this.user));
    }
  }

  private hasFuncShow(func: string): boolean {
    return this.user?.functions?.includes(func);
  }

  private funcRequired(funcs: string[]): void {
    const found = funcs.find((func) => this.hasFuncShow(func));
    if (found) {
      return; // いずれかの権限があればOK
    }
    console.log('go to login');
    this.router.navigate(['/login']);
  }
}
