import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { CheckComponent } from './components/register/check/check.component';
import { SearchComponent } from './components/products/search/search.component';
import { ProductShareComponent } from './components/product-share/product-share.component';
import { RegisterComponent } from './components/product-share/register/register.component';
import { StoreComponent } from './components/product-share/store/store.component';
import { PriceTrackerComponent } from './components/price-tracker/price-tracker.component';
import { BiccameraComponent } from './components/biccamera/biccamera.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { IrisComponent } from './components/iris/iris.component';
import { CallbackComponent } from './components/oauth/callback/callback.component';
import { KojimaComponent } from './components/kojima/kojima.component';
import { YamadaComponent } from './components/yamada/yamada.component';
import { EdionComponent } from './components/edion/edion.component';
import { DonkiComponent } from './components/donki/donki.component';
import { DiscontinueComponent } from './components/discontinue/discontinue.component';
import { AmazonNoficationComponent } from './components/amazon-notification/amazon-notification.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register/:token', component: CheckComponent },
  { path: 'products/search', component: SearchComponent },
  { path: 'share', component: ProductShareComponent },
  { path: 'share/register', component: RegisterComponent },
  { path: 'share/store', component: StoreComponent },
  { path: 'amazon-notification', component: AmazonNoficationComponent },
  { path: 'price-tracker', component: PriceTrackerComponent },
  { path: 'biccamera', component: BiccameraComponent },
  { path: 'kojima', component: KojimaComponent },
  { path: 'changelog', component: ChangelogComponent },
  { path: 'iris', component: IrisComponent },
  { path: 'yamada', component: YamadaComponent },
  { path: 'edion', component: EdionComponent },
  { path: 'donki', component: DonkiComponent },
  { path: 'discontinue', component: DiscontinueComponent },
  { path: 'oauth/callback', component: CallbackComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
