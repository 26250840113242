import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AmazonCategoryService } from 'src/app/services/amazon-category.service';
import { MessageService } from 'src/app/services/message.service';
import { RoutingService } from 'src/app/services/routing.service';
import { SubtitleService } from 'src/app/services/subtitle.service';
import { UserService } from 'src/app/services/user.service';
import { DiscontinueService } from 'src/app/services/discontinue.service';
import { DiscontinueProductsQuery, SearchDiscontinuedProductInput } from 'src/gql/generated';
import moment from 'moment';

@Component({
  selector: 'app-discontinue',
  templateUrl: './discontinue.component.html',
  styleUrl: './discontinue.component.sass',
})
export class DiscontinueComponent {
  products: DiscontinueProduct[] = [];

  form: UntypedFormGroup;

  janOrAsin: string;
  categories: { label: string; value: number }[] = [];
  categoryVal: string;
  fromDate: Date;
  amazonCategories: Array<AmazonCategory>;
  amazonCategoryVal: string;

  showRegisterCategoryModal = false;
  showRegisterJansModal = false;
  showMemoModal = false;
  memoTargetId: number;
  memo: string;

  registerCategoryName: string;

  registerJans: string;
  registerCategory: { label: string; value: number };

  constructor(
    private router: Router,
    private routingService: RoutingService,
    private userService: UserService,
    private builer: UntypedFormBuilder,
    private subtitleService: SubtitleService,
    private amazonCategoryService: AmazonCategoryService,
    private messageService: MessageService,
    private service: DiscontinueService,
  ) {
    this.routingService.loginRequired();
    this.userService.discontinueRequired();
    this.subtitleService.setSubTitle('廃盤商品管理');
  }

  ngOnInit(): void {
    this.initForm();
    this.loadProducts();
    this.loadCategory();
    this.loadAmazonCategory();
  }

  initForm() {
    this.form = this.builer.group({
      keyword: [this.janOrAsin, []],
      category: [this.categoryVal, []],
      fromDate: [this.fromDate, []],
      amazonCategory: [this.amazonCategoryVal, []],
    });
  }

  async loadProducts() {
    const resp = await this.service.fetchProducts({});
    this.mapToProducts(resp);
  }

  async loadCategory() {
    const resp = await this.service.fetchCategories();
    if (resp?.discontinuedCategories) {
      this.categories = resp.discontinuedCategories.map((c) => {
        return {
          label: c.name,
          value: Number(c.id),
        };
      });
    }
  }

  loadAmazonCategory() {
    this.amazonCategoryService.list(true, (resp) => {
      this.amazonCategories = resp.amazonCategories.map((c) => {
        return {
          id: c.id,
          name: c.name,
        };
      });
    });
  }

  goBack() {
    this.router.navigate(['/']);
  }

  toggleRegisterCategoryModal() {
    this.showRegisterCategoryModal = !this.showRegisterCategoryModal;
  }

  toggleRegisterJansModal() {
    this.showRegisterJansModal = !this.showRegisterJansModal;
  }

  async doRegisterCategory() {
    if (!this.registerCategoryName) {
      this.messageService.setFlashMessageWithClear('error', 'エラー', 'カテゴリ名を入力してください');
      return;
    }
    const resp = await this.service.registerCategoryName(this.registerCategoryName);
    if (resp?.registerDiscontinuedCategory) {
      this.messageService.setFlashMessageWithClear('success', '成功', 'カテゴリを登録しました');
      this.registerCategoryName = '';
      this.toggleRegisterCategoryModal();
      this.loadCategory();
    }
  }

  async doRegisterJans() {
    console.log(this.registerJans, this.registerCategory);
    if (!this.registerJans || !this.registerCategory) {
      this.messageService.setFlashMessageWithClear('error', 'エラー', '廃盤対象のJAN / 対象を入力してください');
      return;
    }
    const jans = this.registerJans
      .split('\n')
      .map((j) => j.trim())
      .filter((j) => j !== '');
    if (jans.length === 0) {
      this.messageService.setFlashMessageWithClear('error', 'エラー', 'JANが入力されていません');
      return;
    }

    const invalidJans = jans.filter((j) => !this.isValidJan(j));
    if (invalidJans.length > 0) {
      this.messageService.setFlashMessageWithClear(
        'error',
        'エラー',
        '不正なJANが見つかりました: [' + invalidJans.join(', ') + '] 修正後に再登録してください。',
        30000,
      );
      return;
    }
    try {
      const resp = await this.service.registerJans(jans, this.registerCategory.value);
      if (resp?.registerDiscontinuedJans) {
        this.messageService.setFlashMessageWithClear('success', '成功', '廃盤商品のJANを登録しました');
        this.registerJans = '';
        this.registerCategory = null;
        this.toggleRegisterJansModal();
        this.doSearch();
      }
    } catch (e) {
      console.error(e);
      this.messageService.setFlashMessageWithClear(
        'error',
        'エラー',
        '予期せぬエラーが発生しました。詳細: ' + e.message,
      );
    }
  }

  async doRegisterMemo() {
    if (!this.memo || !this.memoTargetId) {
      this.messageService.setFlashMessageWithClear(
        'error',
        'エラー',
        'メモが入力されていない可能性があります。確認してください。',
      );
      return;
    }
    const resp = await this.service.upsertMemo(this.memoTargetId, this.memo);
    if (resp?.upsertDiscontinuedMemo) {
      this.messageService.setFlashMessageWithClear('success', '成功', 'メモを登録しました');
      this.memo = '';
      this.showMemoModal = false;
      this.memoTargetId = null;
      await this.doSearch();
    }
  }

  async doSearch() {
    const keywords = this.form
      .get('keyword')
      .value?.split('\n')
      .filter((k: string) => k.trim() !== '');
    const jans = keywords?.filter((k: string) => this.isValidJan(k));
    const asins = keywords?.filter((k: string) => this.isValidAsin(k));
    const categoryIDs = this.form.get('category').value;
    const fromDate = this.form.get('fromDate').value && moment(this.form.get('fromDate').value).format('YYYY-MM-DD');
    const amazonCategoryId = this.form.get('amazonCategory').value?.id;

    const input: SearchDiscontinuedProductInput = {
      jans,
      asins,
      categoryIDs,
      fromDate,
      amazonCategoryId,
    };
    const resp = await this.service.fetchProducts(input);
    this.mapToProducts(resp);
  }

  outputCsv() {}

  openMemoModal(product: DiscontinueProduct) {
    this.showMemoModal = true;
    this.memoTargetId = product.id;
  }

  private mapToProducts(resp: DiscontinueProductsQuery) {
    if (!resp?.discontinuedProducts) {
      return;
    }
    this.products = resp.discontinuedProducts.map((d) => {
      const p = d.product;
      const aps = p.amazonProducts;
      const ap = aps?.length > 0 ? aps.find((a) => a.isMain) || aps[0] : null;
      return {
        id: Number(d.id),
        jan: p.jan,
        asin: ap?.asin,
        imageUrl: ap?.imageUrl,
        productName: ap?.name,
        amazonCategory: ap?.topCategory,
        amazonPrice: ap?.latestPrice ?? -1,
        category: d.discontinuedCategories?.map((c) => c.name).join(', '),
        discontinuedAt: d.discontinuedAt,
        memo: d.memo,
      };
    });
  }

  private isValidJan(jan: string) {
    if (jan.length !== 13) {
      return false;
    }
    if (!jan.match(/^[0-9]+$/)) {
      return false;
    }
    return true;
  }

  private isValidAsin(asin: string): boolean {
    // ASIN must be exactly 10 characters long
    if (asin.length !== 10) {
      return false;
    }
    // ASIN must contain only alphanumeric characters
    if (!/^[A-Z0-9]+$/i.test(asin)) {
      return false;
    }
    return true;
  }
}

interface AmazonCategory {
  id: number;
  name: string;
}

type DiscontinueProduct = {
  id: number;
  jan: string;
  asin: string;
  imageUrl: string;
  productName: string;
  amazonCategory: string;
  amazonPrice: number;
  category: string;
  discontinuedAt: string;
  memo: string;
};
