import { Component, Input, OnInit } from '@angular/core';
import { YamadaStock } from '../../models/yamada-product';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  FoundDonkiStockGQL,
  FoundEdionStockGQL,
  FoundYamadaStockGQL,
  NotFoundDonkiStockGQL,
  NotFoundEdionStockGQL,
  NotFoundYamadaStockGQL,
  PurchaseCancelDonkiStockGQL,
  PurchaseCancelEdionStockGQL,
  PurchaseCancelYamadaStockGQL,
  PurchaseDonkiStockGQL,
  PurchaseEdionStockGQL,
  PurchaseYamadaStockGQL,
  UpsertDonkiProductMemoGQL,
  UpsertEdionProductMemoGQL,
  UpsertYamadaProductMemoGQL,
} from 'src/gql/generated';
import { firstValueFrom } from 'rxjs';
import { EdionStock } from 'src/app/models/edion-product';
import { UserService } from 'src/app/services/user.service';
import { DonkiStock } from 'src/app/models/donki-product';

type Stock = YamadaStock | EdionStock | DonkiStock;

@Component({
  selector: 'app-spreadsheet-stock-badge',
  templateUrl: './spreadsheet-stock-badge.component.html',
  styleUrls: ['./spreadsheet-stock-badge.component.sass'],
})
export class SpreadsheetStockBadgeComponent implements OnInit {
  @Input() stocks: Stock[];
  @Input() showPurchased: boolean;

  memoModel: Stock = {} as Stock;
  showMemo = false;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userService: UserService,
    private yamadaMemoGQL: UpsertYamadaProductMemoGQL,
    private edionMemoGQL: UpsertEdionProductMemoGQL,
    private donkiMemoGQL: UpsertDonkiProductMemoGQL,
    private purchaseYamadaGQL: PurchaseYamadaStockGQL,
    private purchaseEdionGQL: PurchaseEdionStockGQL,
    private purchaseDonkiGQL: PurchaseDonkiStockGQL,
    private purchaseCancelYamadaGQL: PurchaseCancelYamadaStockGQL,
    private purchaseCancelEdionGQL: PurchaseCancelEdionStockGQL,
    private purchaseCancelDonkiGQL: PurchaseCancelDonkiStockGQL,
    private notFoundYamadaGQL: NotFoundYamadaStockGQL,
    private notFoundEdionGQL: NotFoundEdionStockGQL,
    private notFoundDonkiGQL: NotFoundDonkiStockGQL,
    private foundYamadaGQL: FoundYamadaStockGQL,
    private foundEdionGQL: FoundEdionStockGQL,
    private foundDonkiGQL: FoundDonkiStockGQL,
  ) {}

  ngOnInit(): void {}

  getStyle(hasStock: boolean): any {
    if (hasStock) {
      return null;
    } else {
      return {
        background: 'gray',
      };
    }
  }

  getClass(stock: Stock): string {
    if (this.showPurchased) {
      return '';
    }

    if (stock.isPurchased) {
      return 'display: none;';
    } else {
      return '';
    }
  }

  getStoreName(code: string, name: string): string {
    const append = code ? '[' + code + ']' : '';
    return append + name.trim();
  }

  // --------------------------------------------------------
  //                                                 Purchase
  // --------------------------------------------------------

  confirm(event: Event, stock: Stock): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'この商品を購入しましたか？もしくは展示商品ですか？',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        var result = false;
        switch (stock.type) {
          case 'yamada':
            result = await this.purchaseYamada(stock.id);
            break;
          case 'edion':
            result = await this.purchaseEdion(stock.id);
            break;
          case 'donki':
            result = await this.purchaseDonki(stock.id);
            break;
          default:
            console.log('unknown stock type: ', stock);
        }
        if (result) {
          stock.isPurchased = true;
          stock.purchasedUserName = this.userService.getName();
        }
      },
      reject: () => {},
    });
  }

  confirmCancel(event: Event, stock: Stock): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'この商品を購入/展示をキャンセルしますか？',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        var result = false;
        switch (stock.type) {
          case 'yamada':
            result = await this.purchaseCancelYamada(stock.id);
            break;
          case 'edion':
            result = await this.purchaseCancelEdion(stock.id);
            break;
          case 'donki':
            result = await this.purchaseCancelDonki(stock.id);
            break;
          default:
            console.log('unknown stock type: ', stock);
        }
        if (result) {
          stock.isPurchased = false;
          stock.purchasedUserName = null;
        }
      },
      reject: () => {},
    });
  }

  async purchaseYamada(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.purchaseYamadaGQL.mutate({ id }));
    if (resp.data?.purchaseYamadaStock) {
      this.showSuccess();
      return true;
    }
    this.showError();
    return false;
  }

  async purchaseCancelYamada(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.purchaseCancelYamadaGQL.mutate({ id }));
    if (resp.data?.purchaseCancelYamadaStock) {
      this.showSuccess(true);
      return true;
    }
    this.showError();
    return false;
  }

  async purchaseEdion(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.purchaseEdionGQL.mutate({ id }));
    if (resp.data?.purchaseEdionStock) {
      this.showSuccess();
      return true;
    }
    this.showError();
    return false;
  }

  async purchaseCancelEdion(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.purchaseCancelEdionGQL.mutate({ id }));
    if (resp.data?.purchaseCancelEdionStock) {
      this.showSuccess(true);
      return true;
    }
    this.showError();
    return false;
  }

  async purchaseDonki(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.purchaseDonkiGQL.mutate({ id }));
    if (resp.data?.purchaseDonkiStock) {
      this.showSuccess();
      return true;
    }
    this.showError();
    return false;
  }

  async purchaseCancelDonki(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.purchaseCancelDonkiGQL.mutate({ id }));
    if (resp.data?.purchaseCancelDonkiStock) {
      this.showSuccess(true);
      return true;
    }
    this.showError();
    return false;
  }

  showSuccessNotFound(isFound: boolean = false): void {
    const partial = isFound ? '試無ではない状態' : '試無';
    this.messageService.add({
      severity: 'info',
      summary: '成功',
      detail: `対象の在庫を${partial}に変更しました`,
      life: 3000,
    });
  }

  // --------------------------------------------------------
  //                                                     Memo
  // --------------------------------------------------------

  openMemo(stock: Stock) {
    this.memoModel = stock;
    this.showMemo = true;
  }

  async doRegisterMemo() {
    var result = false;
    switch (this.memoModel.type) {
      case 'yamada':
        result = await this.registerMemoYamada();
        break;
      case 'edion':
        result = await this.purchaseEdion(this.memoModel.id);
        break;
      case 'donki':
        result = await this.purchaseDonki(this.memoModel.id);
        break;
      default:
        console.log('unknown stock type: ', this.memoModel);
    }
    if (result) {
      this.memoModel = {} as Stock;
      this.showMemo = false;
    }
  }

  async registerMemoYamada(): Promise<boolean> {
    const resp = await firstValueFrom(
      this.yamadaMemoGQL.mutate({
        id: this.memoModel.id,
        memo: this.memoModel.memo,
      }),
    );
    if (resp.data?.upsertYamadaProductMemo) {
      this.showSuccessMemo();
      return true;
    }
    this.showError();
    return false;
  }

  async registerMemoEdion(): Promise<boolean> {
    const resp = await firstValueFrom(
      this.edionMemoGQL.mutate({
        id: this.memoModel.id,
        memo: this.memoModel.memo,
      }),
    );
    if (resp.data?.upsertEdionProductMemo) {
      this.showSuccessMemo();
      return true;
    }
    this.showError();
    return false;
  }

  async registerMemoDonki(): Promise<boolean> {
    const resp = await firstValueFrom(
      this.donkiMemoGQL.mutate({
        id: this.memoModel.id,
        memo: this.memoModel.memo,
      }),
    );
    if (resp.data?.upsertDonkiProductMemo) {
      this.showSuccessMemo();
      return true;
    }
    this.showError();
    return false;
  }

  showSuccessMemo(): void {
    this.messageService.add({
      severity: 'info',
      summary: '成功',
      detail: 'メモを登録しました',
      life: 3000,
    });
  }

  // --------------------------------------------------------
  //                                                 NotFound
  // --------------------------------------------------------

  confirmNotFound(event: Event, stock: Stock): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'この商品を試無にしますか？',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        var result = false;
        switch (stock.type) {
          case 'yamada':
            result = await this.notFoundYamada(stock.id);
            break;
          case 'edion':
            result = await this.notFoundEdion(stock.id);
            break;
          case 'donki':
            result = await this.notFoundDonki(stock.id);
            break;
          default:
            console.log('unknown stock type: ', stock);
        }
        if (result) {
          stock.isNotFound = true;
        }
      },
      reject: () => {},
    });
  }

  confirmFound(event: Event, stock: Stock): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'この商品の試無をキャンセルしますか？',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        var result = false;
        switch (stock.type) {
          case 'yamada':
            result = await this.foundYamada(stock.id);
            break;
          case 'edion':
            result = await this.foundEdion(stock.id);
            break;
          case 'donki':
            result = await this.foundDonki(stock.id);
            break;
          default:
            console.log('unknown stock type: ', stock);
        }
        if (result) {
          stock.isNotFound = false;
        }
      },
      reject: () => {},
    });
  }

  async notFoundYamada(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.notFoundYamadaGQL.mutate({ id }));
    if (resp.data?.notFoundYamadaStock) {
      this.showSuccess();
      return true;
    }
    this.showError();
    return false;
  }

  async foundYamada(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.foundYamadaGQL.mutate({ id }));
    if (resp.data?.foundYamadaStock) {
      this.showSuccess(true);
      return true;
    }
    this.showError();
    return false;
  }

  async notFoundEdion(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.notFoundEdionGQL.mutate({ id }));
    if (resp.data?.notFoundEdionStock) {
      this.showSuccess();
      return true;
    }
    this.showError();
    return false;
  }

  async foundEdion(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.foundEdionGQL.mutate({ id }));
    if (resp.data?.foundEdionStock) {
      this.showSuccess(true);
      return true;
    }
    this.showError();
    return false;
  }

  async notFoundDonki(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.notFoundDonkiGQL.mutate({ id }));
    if (resp.data?.notFoundDonkiStock) {
      this.showSuccess();
      return true;
    }
    this.showError();
    return false;
  }

  async foundDonki(id: string): Promise<boolean> {
    const resp = await firstValueFrom(this.foundDonkiGQL.mutate({ id }));
    if (resp.data?.foundDonkiStock) {
      this.showSuccess(true);
      return true;
    }
    this.showError();
    return false;
  }

  showSuccess(isCancel: boolean = false): void {
    const partial = isCancel ? 'キャンセル' : '購入済み';
    this.messageService.add({
      severity: 'info',
      summary: '成功',
      detail: `対象の在庫を${partial}に変更しました`,
      life: 3000,
    });
  }

  // --------------------------------------------------------
  //                                                   Common
  // --------------------------------------------------------

  showError(): void {
    this.messageService.add({
      severity: 'error',
      summary: 'エラーが発生しました',
      detail: '継続する場合は管理者までお問い合わせください',
      life: 3000,
    });
  }
}
