import { Component, OnInit } from '@angular/core';
import { SubtitleService } from 'src/app/services/subtitle.service';
import { UserService } from 'src/app/services/user.service';
import { RoutingService } from 'src/app/services/routing.service';
import { UserSubRole } from 'src/gql/generated';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  isStoreManager = false;
  groupName: string;
  hasRefreshToken = true;

  releaseDate = moment('2023-08-28').add(14, 'd');
  userFeatures: { path: string; icon: string; title: string }[][] = [];

  features = {
    rakuten: {
      path: '/products/search',
      icon: 'pi-shopping-cart',
      title: '商品検索',
    },
    share: {
      path: '/share',
      icon: 'pi-share-alt',
      title: '商品共有',
    },
    amazonNotify: {
      path: '/amazon-notification',
      icon: 'pi-amazon',
      title: 'Amazon通知',
    },
    biccamera: {
      path: '/biccamera',
      icon: 'pi-camera',
      title: 'ビックカメラ',
    },
    kojima: {
      path: '/kojima',
      icon: 'pi-sun',
      title: 'コジマ',
    },
    iris: {
      path: '/iris',
      icon: 'pi-pencil',
      title: 'アイリス',
    },
    yamada: {
      path: '/yamada',
      icon: 'pi-caret-up',
      title: 'ヤマダ',
    },
    edion: {
      path: '/edion',
      icon: 'pi-circle-off',
      title: 'エディオン',
    },
    donki: {
      path: '/donki',
      icon: 'pi-star',
      title: 'ドンキ',
    },
    discontinue: {
      path: '/discontinue',
      icon: 'pi-trash',
      title: '廃盤商品',
    },
  };

  constructor(
    private router: Router,
    private subtitleService: SubtitleService,
    private userService: UserService,
    private routingService: RoutingService,
  ) {
    this.routingService.loginRequired();
    this.subtitleService.setSubTitle('ホーム');
  }

  ngOnInit() {
    this.userService.me((me) => {
      this.isStoreManager = me.isStoreManager;
      this.groupName = me.userGroup.name;
      this.hasRefreshToken = me.hasRefreshToken;

      // Temporary array to collect all features
      let tempFeatures: { path: string; icon: string; title: string }[] = [];

      if (this.canShowRakuten()) {
        tempFeatures.push(this.features.rakuten);
      }
      if (this.canShowShare()) {
        tempFeatures.push(this.features.share);
      }
      if (this.canShowAmazonNotify()) {
        tempFeatures.push(this.features.amazonNotify);
      }
      if (this.canShowBiccamera()) {
        tempFeatures.push(this.features.biccamera);
      }
      if (this.canShowKojima()) {
        tempFeatures.push(this.features.kojima);
      }
      if (this.canShowIris()) {
        tempFeatures.push(this.features.iris);
      }
      if (this.canShowSpreadSheet()) {
        tempFeatures.push(this.features.yamada);
        tempFeatures.push(this.features.edion);
        tempFeatures.push(this.features.donki);
      }
      if (this.canShowDiscontinue()) {
        tempFeatures.push(this.features.discontinue);
      }

      this.userFeatures = [];
      for (let i = 0; i < tempFeatures.length; i += 2) {
        this.userFeatures.push(tempFeatures.slice(i, i + 2));
      }
    });
  }

  getFeatureIndex(
    index: number,
    feature: { path: string; icon: string; title: string }[],
  ): {
    path: string;
    icon: string;
    title: string;
  } {
    if (index < feature.length) {
      return feature[index];
    }
    return null;
  }

  canShowRakuten(): boolean {
    return this.userService.hasFuncRakuten();
  }

  canShowShare(): boolean {
    return this.userService.hasFuncShare();
  }

  canShowAmazonNotify(): boolean {
    return this.userService.hasFuncAmazonNotify();
  }

  canShowBiccamera(): boolean {
    return this.userService.hasFuncBiccamera();
  }

  canShowKojima(): boolean {
    return this.userService.hasFuncKojima();
  }

  canShowIris(): boolean {
    return this.userService.hasFuncIris();
  }

  canShowSpreadSheet(): boolean {
    return this.userService.hasFuncSpreadSheet();
  }

  canShowDiscontinue(): boolean {
    return this.userService.hasFuncDiscontinue();
  }

  canShowSearch(): boolean {
    return this.isStoreManager || this.userService.hasSubRole(UserSubRole.ProductShare);
  }

  goHistory() {
    this.router.navigate(['/changelog']);
  }

  showNewIcon() {
    return moment().unix() < this.releaseDate.unix();
  }
}
